.logo-title {
  color: #061688;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.logo-sidebar {
  color: #fff;
  background: rgb(255 255 255) !important;
  padding: 80px;
}

.c-sidebar {
  /* background: #36473d; */
  /* opacity: 0.2; */
  /* filter: alpha(opacity=1); IE8 and lower */
  /* zoom: 1;  */
  /* set "zoom", "width" or "height" to trigger "hasLayout" in IE 7 and lower */
}
.c-sidebar-brand {
  background: white !important;
  padding: 5px;
  border: 1px solid #ebedef;
}

.c-body {
  background: #ffffff;
}

.c-header .c-subheader {
  background: #ebedef;
}

.c-sidebar-nav {
  background: #07168961;
}
