.main-section {
    font-family: dm sans, sans-serif;
    color: #1d1e20;
    display: flex;
    justify-content: flex-end;
    height: 100vh;
    background: url(/src/img/CoopQuisqueyalogin.png) no-repeat fixed left center;
    /* background: url(/src/img/background2.svg) no-repeat fixed left center; */
    /* background: url(/src/assets/images/image4.jpg) no-repeat fixed left center; */
    background-size: cover;
  
  }
  .main-section .login-section {
    display: flex;
    flex: 1 0 50%;
    align-items: center;
    max-width: 612px;
    line-height: 24px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.95) 20%, 
      rgba(255, 255, 255, 0.65) 100%
    );
    backdrop-filter: blur(3px);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    /* box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10); */
  }
  
  .loginfotter {
    background: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    min-height: 3.35rem;
    transition: all 0.3s ease;
  }
  

  .login-wrapper {
    width: 100%;
    max-width: 524px;
    padding: 24px 40px;
    margin: auto;
  }
  
  .login-wrapper h2 {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    font-weight: 500;
  }

  .login-wrapper img#logo {
    margin-bottom: 40px;
    top: initial;
    display: block;
    width: 260px;
  }

  .LoginLabel {
    height: initial;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
  }
  .LoginInput2 {
    height: initial;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #dad9da;
    border-radius: 4px;
  }